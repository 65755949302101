import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { useOrganizationQuery } from "@/lib/hooks/queries/Organization";

import { useCurrentUserQuery } from "@/lib/hooks/queries/User";
import { OrgNameDisplay, OrgNameDisplaySkeleton } from "./OrgNameDisplay";

export function OrgGeneralPage() {
  const { data: currentUser } = useCurrentUserQuery();
  const organizationId = currentUser?.user?.organization?.id;

  const { data, isLoading } = useOrganizationQuery({
    id: Number(organizationId),
  });

  return (
    <DashboardScreen className="flex flex-col gap-4" scrollable>
      {isLoading ? (
        <OrgNameDisplaySkeleton />
      ) : data?.organization ? (
        <OrgNameDisplay name={data.organization.name} />
      ) : (
        <div>No organization found</div>
      )}
    </DashboardScreen>
  );
}
