import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import { formatEnumLabel } from "@/lib/formatEnumLabel";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { Icons } from "../../../components/Icons";
import { DatePickerWithRange } from "./DatePickerWithRange";
import { useDashHomeStore } from "./dashHomeStore";

export const HomeHeader = WithSuspenseAndErrorBoundary({
  Component: function _HomeHeader() {
    const { setDateRangeType, setDateRange, dateRangeType, dateRange } =
      useDashHomeStore();
    const now = new Date();

    const currentHour = now.getHours();
    let greeting = "";

    if (currentHour >= 0 && currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }

    const { data } = useCurrentUserQuery();
    const user = data?.user;
    const { firstName, lastName, email } = user ?? {};
    const showFullName = firstName && lastName;
    const fullName = showFullName ? `${firstName} ${lastName}` : email;
    const initials = showFullName
      ? `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
      : email?.charAt(0).toUpperCase();
    const role = user?.role;
    const orgName = user?.organization?.name;

    return (
      <div className="flex w-full flex-1 flex-row justify-between">
        {/* Profile */}
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              <div className="relative h-16 w-16 rounded-full bg-primary/70">
                <span className="absolute inset-0 flex items-center justify-center text-lg font-semibold text-accent-foreground">
                  {initials}
                </span>
              </div>
              <div>
                <div className="flex items-center">
                  <h1 className="ml-3 text-2xl font-bold leading-7 sm:truncate sm:leading-9">
                    {greeting},{` ${fullName}`}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Organization Name</dt>
                  <dd className="flex items-center text-sm font-medium capitalize sm:mr-6">
                    <Icons.building
                      className="mr-1.5 h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    {orgName}
                  </dd>
                  <dt className="sr-only">User Role</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize sm:mr-6 sm:mt-0">
                    <Icons.checkCircle
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-status-success-solid"
                      aria-hidden="true"
                    />
                    {formatEnumLabel(role)}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Calendar */}
        <DatePickerWithRange
          dateRangeType={dateRangeType}
          setDateRangeType={setDateRangeType}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </div>
    );
  },
});
