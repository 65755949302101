import { Link, useNavigate, useParams } from "react-router-dom";

import { ValidationExcelTableDataWrapper } from "@/components/shared-components/validation-excel-table/ValidationExcelTableDataWrapper";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { buttonVariants } from "@/components/ui/button";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/cn";

import { useValidationStore } from "../../../../../../../components/shared-components/validation-excel-table/ValidationStore";
import { BodyLayout } from "../../BodyLayout";
import { Header } from "../../Header";
import { SubmissionRoutes } from "@/app/vendor-submit/vendorSubmitRoutes";

export const ValidationPage = () => {
  const { vendorId: vId, submissionId: pId } = useParams();
  const vendorId = Number(vId);
  const submissionId = Number(pId);
  const { validationErrors } = useValidationStore();
  const navigate = useNavigate();

  return (
    <ScrollArea className="max-w-none">
      <BodyLayout>
        <Header
          title="Review Validated Data"
          description="We've run validations as set by org standards. If you need to make any changes, please do so below and resubmit the file."
        />
        <div className="pt-6">
          {validationErrors.length === 0 ? (
            <HoverBorderGradient
              as="button"
              containerClassName=""
              onClick={() => navigate(`../../${SubmissionRoutes.Review}`)}
              className={cn(
                "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
              )}
            >
              Back To Review
            </HoverBorderGradient>
          ) : (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <HoverBorderGradient
                  as="button"
                  containerClassName=""
                  className={cn(
                    "min-w-[250px] bg-muted hover:bg-accent hover:text-accent-foreground",
                  )}
                >
                  Back To Review
                </HoverBorderGradient>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>Are you sure?</AlertDialogHeader>
                <AlertDialogDescription>
                  {`You are about to accept a submission that contains
                    ${validationErrors.length} unresolved issues that may have resulted in incorrect data processing.
                    Fixing these errors and resubmitting will give you the best data quality.`}
                </AlertDialogDescription>
                <AlertDialogFooter>
                  <AlertDialogCancel>Continue Fixing Issues</AlertDialogCancel>
                  <AlertDialogAction>
                    <Link
                      to={`../../${SubmissionRoutes.Review}`}
                      className={buttonVariants({
                        variant: "default",
                      })}
                    >
                      Back To Review
                    </Link>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
        <div className="w-full py-12">
          <ValidationExcelTableDataWrapper
            vendorId={vendorId}
            submissionId={submissionId}
          />
        </div>
      </BodyLayout>
    </ScrollArea>
  );
};
