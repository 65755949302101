import { graphql } from "@/gql";
import { useGraphqlQuery } from "../graphql";

export const useGetOrgVendors = () => {
  return useGraphqlQuery({
    queryKey: ["useGetOrgVendors"],
    query: graphql(`
      query OrgVendors($where: VendorWhereInput) {
        vendors(where: $where) {
          id
          name
          auth0Id
        }
      }
    `),
  });
};
