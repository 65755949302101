import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/Icons";

export default function UnauthorizedPage() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-2">
        <Icons.lock className="h-12 w-12 text-muted-foreground" />
        <h1 className="text-2xl font-semibold">Access Denied</h1>
        <p className="text-muted-foreground">
          You don't have permission to access this area.
        </p>
      </div>
      <div className="flex gap-4">
        <Button variant="outline">
          <Link to="/vendor-submit">Go to Submission Portal</Link>
        </Button>
        <Button>
          <Link to="/help">Need Help?</Link>
        </Button>
      </div>
    </div>
  );
}
