import { useState } from "react";
import { z } from "zod";
import { useIsOrgAdmin } from "@/lib/hooks/permissions";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useInviteUser } from "@/lib/hooks/mutations/useInviteAuth0User";
import { UserRole } from "@/gql/graphql";
import { AdminRequiredTooltip } from "@/components/ui/admin-required-tooltip";
import { toast } from "sonner";
import { queryClient } from "@/queryClient";
import { useGetOrgVendors } from "@/lib/hooks/queries/useGetOrgVendors";

const emailSchema = z.string().email("Invalid email");

export const MemberInviteForm = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRole>(UserRole.OrgAdmin);
  const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: inviteUser } = useInviteUser();
  const isOrgAdmin = useIsOrgAdmin();
  const { data: vendorsData } = useGetOrgVendors();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const handleRoleChange = (value: UserRole) => {
    setRole(value);
    setSelectedVendorId(null);
  };

  const handleSubmit = () => {
    try {
      emailSchema.parse(email);
      setEmailError(null);

      setIsLoading(true);
      inviteUser(
        {
          email,
          role,
          partnerAuth0Id:
            role === UserRole.Partner && selectedVendorId
              ? selectedVendorId
              : undefined,
        },
        {
          onSuccess: () => {
            // Reset form on success
            setEmail("");
            setRole(UserRole.OrgAdmin);
            toast.success("User invited successfully");
            queryClient.invalidateQueries({
              queryKey: ["organizationInvitations", 1],
            });
          },
          onError: (error) => {
            toast.error(error.message);
          },
        },
      );
    } catch (error) {
      if (error instanceof z.ZodError) {
        setEmailError(error.errors[0].message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isDirty =
    email !== "" || role !== UserRole.OrgAdmin || selectedVendorId !== null;
  const isSubmitDisabled =
    !isDirty ||
    !email ||
    !isOrgAdmin ||
    (role === UserRole.Partner && !selectedVendorId);

  return (
    <Card className="h-fit max-h-min max-w-fit">
      <CardHeader className="flex gap-2 p-4">
        <CardTitle>Add New Member</CardTitle>
        <CardDescription>Invite a new member by email address</CardDescription>
      </CardHeader>
      <CardContent className="flex gap-3 p-4 pt-0">
        <AdminRequiredTooltip message="Only organization administrators can invite new members">
          <div className="relative">
            <Input
              placeholder=""
              className="w-64"
              value={email}
              onChange={handleEmailChange}
              disabled={!isOrgAdmin}
            />
            {emailError && (
              <p className="absolute -top-6 pl-2 pt-1 text-xs text-red-400/70">
                {emailError}
              </p>
            )}
          </div>
        </AdminRequiredTooltip>
        <div className="relative">
          <Select
            onValueChange={handleRoleChange}
            value={role}
            defaultValue={UserRole.OrgAdmin}
            disabled={!isOrgAdmin}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Object.values(UserRole).map((roleOption) => {
                if (roleOption === UserRole.Developer) return null;
                return (
                  <SelectItem key={roleOption} value={roleOption}>
                    {roleOption}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        {role === UserRole.Partner && (
          <div className="relative">
            <Select
              onValueChange={(value) => setSelectedVendorId(value)}
              value={selectedVendorId ?? undefined}
              disabled={!isOrgAdmin}
            >
              <SelectTrigger className="w-[280px]">
                <SelectValue placeholder="Select a partner..." />
              </SelectTrigger>
              <SelectContent>
                {vendorsData?.vendors?.map((vendor) => (
                  <SelectItem key={vendor.auth0Id} value={vendor.auth0Id}>
                    {vendor.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
      </CardContent>
      <Separator orientation="horizontal" className="mb-4" />
      <CardFooter className=" px-4 pb-3">
        <AdminRequiredTooltip message="Only organization administrators can invite new members">
          <Button
            size="sm"
            className="w-32"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={isSubmitDisabled}
          >
            Invite
          </Button>
        </AdminRequiredTooltip>
      </CardFooter>
    </Card>
  );
};
