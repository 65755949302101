import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { SubmissionSummaryCard } from "./SubmissionSummaryCard";
import { SubmissionSummaryCardSkeleton } from "./SubmissionSummaryCardSkeleton";
import { ValidationExcelTableSkeleton } from "../../../../../../../components/shared-components/validation-excel-table/ValidationExcelTableSkeleton";
import { getAllProductsSold } from "./getAllProductsSold";
import { getSummaryDataQueryOptions } from "./getSummaryDataQueryOptions";
import { SubmissionChartCard } from "./SubmissionChartCard";
import { SubmissionChartCardSkeleton } from "./SubmissionChartCardSkeleton";
import { SubmissionProductsTable } from "./SubmissionProductsTable";
import { useGetValidationTableHeadersAndRows } from "@/components/shared-components/validation-excel-table/useGetValidationTableHeadersAndRows";
import { useExcelWorkbookFromFileId } from "@/lib/hooks/useExcelWorkbookFromFileId";

export enum LabelNameEnum {
  CPULine = "CPU Line",
  CPUName = "CPU Name",
  GPULine = "GPU Line",
  GPUName = "GPU Name",
}

export const SummaryPageAnalytics = () => {
  const { submissionId: sId } = useParams();
  const submissionId = Number(sId);

  const { data, isLoading: isSummaryDataLoading } = useQuery(
    getSummaryDataQueryOptions(submissionId),
  );

  const fileId = data?.submission?.transformations[0]?.outputFileId ?? -1;

  const { worksheet } = useExcelWorkbookFromFileId(fileId);

  const { rowsValues } = useGetValidationTableHeadersAndRows({
    worksheet,
  });

  if (isSummaryDataLoading) {
    return (
      <div className="grid w-full grid-cols-2 grid-rows-[375px_1fr] gap-6">
        <SubmissionSummaryCardSkeleton />
        <SubmissionChartCardSkeleton />
        <div className="col-span-2 rounded-lg border">
          <ValidationExcelTableSkeleton />
        </div>
      </div>
    );
  }
  // console.log({ rowsValues, data });

  // for debugging
  // const rowIndexes = data?.submission?.transformations[1]?.predictions.map(
  //   (prediction) =>
  //     prediction.metadata.find((metadata) => metadata.key === "rowIndex")
  //       ?.value,
  // );

  // console.log({ rowIndexes });

  const submissionType = data?.submission?.type?.type; //INV or POS

  const submission = data?.submission;
  const transformations = data?.submission?.transformations;

  const productSummaryData = getAllProductsSold({
    transformations: transformations ?? [],
  });

  const submissionWeek = submission?.file.createdAt
    ? format(new Date(submission?.file.createdAt), "w")
    : "No Submission Period Found";
  const fileName = submission?.file.name;

  const productTableData = [];
  const { validCPUs, validGPUs } = productSummaryData;
  for (const cpu of validCPUs) {
    const cpuProductName = cpu.labels.find(
      (label) => label.name === LabelNameEnum.CPUName,
    )?.value;

    // cpuProductName === "AMD Ryzen 7 5800H Processor" &&
    // console.log({ cpu, cpuProductName });

    if (cpuProductName !== undefined && cpuProductName !== "NULL") {
      // search for the product name in the cpuData array
      const productIndex = productTableData.findIndex(
        (product) => product.name === cpuProductName,
      );
      const randomRebate = Math.floor(Math.random() * 10);

      const predictionRowIndex = cpu.metadata.find(
        (metadata) => metadata.key === "rowIndex",
      )?.value;

      const correspondingRow = rowsValues.find(
        (row) => row.rowIndex === Number(predictionRowIndex),
      );

      const quantityOnHand =
        (correspondingRow?.["Quantity on Hand"] as number) ?? 0;
      const quantityOnOrder =
        (correspondingRow?.["Quantity on Order"] as number) ?? 0;

      if (cpuProductName === "AMD Ryzen 7 5800H Processor") {
        // console.log({
        //   predictionRowIndex,
        //   correspondingRow,
        //   quantityOnHand,
        //   quantityOnOrder,
        // });
      }

      if (productIndex === -1) {
        productTableData.push({
          name: cpuProductName,
          id: cpu.id,
          type: "CPU",
          // TODO: Update quantity properly with the sales data
          quantity: 1,
          quantityOnHand,
          quantityOnOrder,
          rebate: randomRebate,
        });
      } else {
        productTableData[productIndex].quantity += 1;
        productTableData[productIndex].quantityOnHand =
          quantityOnHand + productTableData[productIndex].quantityOnHand;
        productTableData[productIndex].quantityOnOrder =
          quantityOnOrder + productTableData[productIndex].quantityOnOrder;
        productTableData[productIndex].rebate += randomRebate;
      }
    }
  }
  for (const gpu of validGPUs) {
    const gpuProductName = gpu.labels.find(
      (label) => label.name === LabelNameEnum.GPUName,
    )?.value;

    if (gpuProductName !== undefined && gpuProductName !== "NULL") {
      // search for the product name in the gpuData array
      const productIndex = productTableData.findIndex(
        (product) => product.name === gpuProductName,
      );
      const randomRebate = Math.floor(Math.random() * 100);

      const predictionRowIndex = gpu.metadata.find(
        (metadata) => metadata.key === "rowIndex",
      )?.value;

      const correspondingRow = rowsValues.find(
        (row) => row.rowIndex === Number(predictionRowIndex),
      );

      const quantityOnHand =
        (correspondingRow?.["Quantity on Hand"] as number) ?? 0;
      const quantityOnOrder =
        (correspondingRow?.["Quantity on Order"] as number) ?? 0;

      if (productIndex === -1) {
        productTableData.push({
          name: gpuProductName,
          id: gpu.id,
          type: "GPU",
          // TODO: Update quantity properly with the sales data
          quantity: 1,
          quantityOnHand,
          quantityOnOrder,
          rebate: randomRebate,
        });
      } else {
        productTableData[productIndex].quantity += 1;
        productTableData[productIndex].quantityOnHand =
          quantityOnHand + productTableData[productIndex].quantityOnHand;
        productTableData[productIndex].quantityOnOrder =
          quantityOnOrder + productTableData[productIndex].quantityOnOrder;
        productTableData[productIndex].rebate += randomRebate;
      }
    }
  }

  return (
    <div className="grid w-full grid-cols-2 grid-rows-[400px_1fr] gap-6">
      <SubmissionSummaryCard
        submissionWeek={submissionWeek}
        fileName={fileName ?? "No File Name"}
        productSummaryData={productSummaryData}
      />

      <SubmissionChartCard productSummaryData={productSummaryData} />

      <SubmissionProductsTable
        productData={productTableData}
        submissionType={submissionType as "POS" | "INV"}
      />
    </div>
  );
};
