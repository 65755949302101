// MemberRow.tsx
import React from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { OrgUsersTableQuery } from "@/gql/graphql";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { EditUserDialog } from "./EditUserDialog";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";
import { useIsOrgAdmin } from "@/lib/hooks/permissions";
import { AdminRequiredTooltip } from "@/components/ui/admin-required-tooltip";

interface MemberRowProps {
  user: OrgUsersTableQuery["users"][number];
}

export const MemberRow: React.FC<MemberRowProps> = ({ user }) => {
  const { data: currentUser } = useCurrentUserQuery();
  const isOrgAdmin = useIsOrgAdmin();
  const isCurrentUser = currentUser.user?.id === user.id;

  return (
    <div className="flex items-start justify-between border-b px-6 py-4 last:border-b-0">
      <div className="flex gap-6">
        <div className="flex items-start">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border border-border bg-muted text-xl font-medium">
            {user?.firstName?.[0] || user?.lastName?.[0]}
          </div>
        </div>
        <div>
          <h2 className="text text-base font-semibold">
            {user.firstName} {user.lastName}
          </h2>
          <p className="mt-1 text-sm text-muted-foreground">{user.email}</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <p className="text-sm font-medium text-muted-foreground">{user.role}</p>
        <AdminRequiredTooltip message="Only organization administrators can manage members">
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  disabled={!isOrgAdmin}
                >
                  <Icons.ellipses className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <EditUserDialog user={user} />
                {!isCurrentUser && (
                  <DeleteUserDialog
                    userId={String(user.id)}
                    userEmail={user.email}
                  />
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </AdminRequiredTooltip>
      </div>
    </div>
  );
};

export const MemberRowSkeleton: React.FC = () => {
  return (
    <div className="flex items-start justify-between border-b px-6 py-4 last:border-b-0">
      <div className="flex gap-6">
        <div className="flex items-start">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border border-border bg-muted text-xl"></div>
        </div>
        <div>
          <div className="h-4 w-40 animate-pulse rounded-sm bg-border" />
          <div className="mt-1 h-4 w-32 animate-pulse rounded-sm bg-border" />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-4 w-14 animate-pulse rounded-sm bg-border" />
      </div>
    </div>
  );
};
