import { PredictionCardContentLabel } from "@/app/dashboard/deprecated-training/PredictionCardContent";
import { Label as getAllProductsSoldLabel } from "@/app/vendor-submit/:vendorId/submission/:submissionId/summary/analytics/getAllProductsSold";
import { LabelTypeaheadQuery, PredictionRowsQuery } from "@/gql/graphql";

type Label =
  | PredictionRowsQuery["predictions"][0]["labels"][0]
  | PredictionCardContentLabel
  | getAllProductsSoldLabel
  | LabelTypeaheadQuery["labels"][0];

export const getLabelValue = (label: Label) => {
  let value = label.value;
  //  check the feedback array from the back to get the latest feedback or return null if no content
  if (label.feedback.length > 0) {
    label.feedback.forEach((feedback) => {
      if (feedback.content) {
        value = feedback.content;
      }
    });
  }
  return value;
};
