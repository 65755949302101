import { ReactNode } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useIsOrgAdmin } from "@/lib/hooks/permissions";

interface AdminRequiredTooltipProps {
  children: ReactNode;
  message?: string;
}

export function AdminRequiredTooltip({
  children,
  message = "Only organization administrators can perform this action",
}: AdminRequiredTooltipProps) {
  const isOrgAdmin = useIsOrgAdmin();

  if (isOrgAdmin) {
    return <>{children}</>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>{message}</TooltipContent>
    </Tooltip>
  );
}
