import { toast } from "sonner";
import { Spinner } from "@/components/Spinner";
import { deleteCookie } from "./cookie";

export const handleLogout = (logout: Function) => {
  toast("Logging out...", {
    icon: <Spinner />,
  });
  deleteCookie("auth0-jwt");
  logout({
    logoutParams: {
      returnTo: window.location.origin + "/logout",
    },
  });
}; 