import { FC, HTMLAttributes } from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { Icons } from "@/components/Icons";

import { DashHomePage } from "./home/DashHomePage";
// import { ProductsPage } from "./products/ProductsPage";
import { SettingsLayout } from "./settings/SettingsLayout";
import { settingsRoutes } from "./settings/settingsRoutes";
import { SubmissionAnalyticsView } from "./submissions/:submissionId/(predictions)/analytics/SubmissionAnalyticsView";
import { SubmissionCorrectionsView } from "./submissions/:submissionId/(predictions)/SubmissionCorrectionsView";
import { SubmissionCorrectionsLayout } from "./submissions/:submissionId/corrections/SubmissionCorrectionsLayout";
import { SubmissionLayout } from "./submissions/:submissionId/SubmissionLayout";
import { SubmissionsPage } from "./submissions/SubmissionsPage";
// import TrainingPage from "./training/TrainingPage";
import { VendorsPage } from "./vendors/general/VendorsPage";
import { SubmissionMappingPage } from "./vendors/summary/submission/[submissionTypeId]/SubmissionMappingPage";
import { VendorSummary } from "./vendors/summary/VendorSummary";
import { VendorLayout } from "./vendors/VendorLayout";
import { VendorsLayout } from "./vendors/VendorsLayout";
import { redirect } from "react-router-dom";
import { getDecodedJwtCookie } from "@/lib/hooks/Auth0";
import { UserRole } from "@/gql/graphql";
import { AuthenticationGuard } from "../AuthenticationGuard";
import { DashboardLayout } from "./DashboardLayout";

interface BaseRoute {
  id: string;
  path?: string;
  element?: React.ReactNode;
  Icon?: FC<HTMLAttributes<SVGElement>>;
  showInNav?: boolean;
  loader?: (args: LoaderFunctionArgs) => Promise<any>;
  children?: RouteDefinition[];
}

type RouteDefinition = BaseRoute;

const hasValidDashboardRole = (role: UserRole | null) => {
  return (
    role === UserRole.OrgAdmin ||
    role === UserRole.OrgEditor ||
    role === UserRole.Developer
  );
};

export const dashboardRoutes: RouteDefinition = {
  id: "Dashboard",
  path: "dashboard",
  element: <AuthenticationGuard component={DashboardLayout} />,
  loader: async () => {
    const decodedJwt = getDecodedJwtCookie();
    if (decodedJwt === null) return redirect("/login");
    const role = decodedJwt.claims.role as UserRole;
    const partnerId = decodedJwt.claims.partnerId;

    if (role === UserRole.Partner) {
      return redirect(`/vendor-submit/${partnerId}`);
    } else if (!hasValidDashboardRole(role)) {
      return redirect("/unauthorized");
    }

    return null;
  },
  children: [
    {
      id: "Home",
      path: "",
      element: <DashHomePage />,

      Icon: Icons.home,
      showInNav: true,
    },
    {
      id: "Submissions",
      path: "submissions",
      element: <SubmissionsPage />,
      Icon: Icons.submission,
      showInNav: true,
    },
    {
      id: "Submission",
      path: "submissions/:submissionId",
      element: <SubmissionLayout />,
      children: [
        {
          id: "Submission Corrections",
          path: "corrections",
          element: <SubmissionCorrectionsLayout />,
          children: [
            {
              id: "Transformation Predictions Table",
              path: "transformations/:transformationId",
              element: <SubmissionCorrectionsView />,
            },
          ],
        },
        {
          id: "Submission Analytics",
          path: "analytics",
          element: <SubmissionAnalyticsView />,
        },
      ],
    },
    {
      id: "Partners",
      path: "partner",
      element: <VendorsLayout />,
      Icon: Icons.users,
      showInNav: true,
      children: [
        {
          id: "Partner Selection",
          path: "",
          element: <VendorsPage />,
        },
        {
          id: "Partner",
          path: ":vendorId",
          element: <VendorLayout />,
          Icon: Icons.user,
          children: [
            {
              id: "Partner Summary",
              path: "",
              element: <VendorSummary />,
            },
            {
              id: "Partner Mapping",
              path: "mapping/:submissionType",
              element: <SubmissionMappingPage />,
            },
          ],
        },
      ],
    },
    // {
    //   id: "Training",
    //   path: "training",
    //   element: <TrainingPage />,
    //   Icon: Icons.ai,
    //   showInNav: true,
    // },
    // {
    //   id: "Products",
    //   path: "products",
    //   element: <ProductsPage />,
    //   Icon: Icons.package,
    //   showInNav: true,
    // },
    {
      id: "Settings",
      path: "settings",
      element: <SettingsLayout />,
      Icon: Icons.settings,
      showInNav: true,
      children: settingsRoutes,
    },
  ],
};
