import { DashboardScreen } from "@/app/dashboard/DashboardScreen";

import { MemberInviteForm } from "./MemberInviteForm";
import { MembersTable } from "./MembersTable";
// import { InvitationsTable } from "./InvitationsTable";

export function MembersPage() {
  return (
    <DashboardScreen className="flex flex-col gap-6" scrollable>
      <MemberInviteForm />
      <MembersTable />
      {/* <InvitationsTable /> */}
    </DashboardScreen>
  );
}
