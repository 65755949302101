import { toast } from "sonner";
import { Icons } from "@/components/Icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useDeleteAuth0User } from "@/lib/hooks/mutations/useDeleteAuth0User";
import { Spinner } from "@/components/Spinner";

interface DeleteUserDialogProps {
  userId: string;
  userEmail?: string;
}

export const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
  userId,
  userEmail,
}) => {
  const { mutate: deleteUser, isPending: isDeleting } = useDeleteAuth0User();

  const handleDelete = async () => {
    try {
      await deleteUser(userId, {
        onSuccess: () => {
          toast.success("User deleted successfully");
        },
        onError: (error) => {
          toast.error(
            error instanceof Error ? error.message : "Failed to delete user",
          );
        },
      });
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          className="cursor-pointer gap-2 text-destructive focus:text-destructive"
          onSelect={(e) => {
            e.preventDefault();
          }}
        >
          <Icons.trash className="h-4 w-4" />
          Delete User
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the user
            {userEmail && ` (${userEmail})`} and remove their access to the
            organization.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleDelete}
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
          >
            {isDeleting ? (
              <Spinner className="h-4 w-4 animate-spin" />
            ) : (
              "Delete"
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
