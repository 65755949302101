import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/cn";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { SettingsForm } from "../../SettingsForm";
import { useUpdateUserInformation } from "@/lib/hooks/queries/useUpdateUserInformation";
import { UserRole } from "@/gql/graphql";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

type User = {
  __typename?: "User";
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  role: string;
  partnerId?: number | null;
};

const userRoles = [
  { value: UserRole.OrgAdmin, label: "Admin" },
  { value: UserRole.OrgEditor, label: "Editor" },
  { value: UserRole.Partner, label: "Partner" },
] as const;

interface UserInformationFormProps extends React.ComponentProps<typeof Card> {
  user: User;
  showRoleSelect?: boolean;
  onSuccess?: () => void;
}

const userInfoSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  role: z
    .enum([UserRole.OrgAdmin, UserRole.OrgEditor, UserRole.Partner])
    .optional(),
  partnerId: z.number().optional(),
});

// There are some constraints around editing users. Users that have signed up with google Auth cannot be edited with this form.
export const UserInformationForm = ({
  user,
  showRoleSelect = false,
  onSuccess,
  ...props
}: UserInformationFormProps) => {
  const { mutate: updateUserInformation, isPending } = useUpdateUserInformation(
    { onSuccess },
  );
  const { data: currentUser } = useCurrentUserQuery();
  const isCurrentUser = currentUser.user?.id === user.id;

  const isPartner = user.role === UserRole.Partner;

  useEffect(() => {
    form.reset({
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      email: user.email ?? "",
      role: user.role as UserRole.OrgAdmin | UserRole.OrgEditor,
    });
  }, [user]);

  const form = useForm<z.infer<typeof userInfoSchema>>({
    resolver: zodResolver(userInfoSchema),
    defaultValues: {
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      email: user.email ?? "",
      role: user.role as UserRole.OrgAdmin | UserRole.OrgEditor,
    },
  });

  function onSubmit(values: z.infer<typeof userInfoSchema>) {
    updateUserInformation({
      data: {
        firstName: { set: values.firstName },
        lastName: { set: values.lastName },
        email: { set: values.email },
        ...(showRoleSelect && values.role && { role: { set: values.role } }),
        ...(showRoleSelect &&
          values.role === UserRole.Partner && {
            partnerId: { set: values.partnerId },
          }),
      },
      where: {
        id: user.id,
      },
    });
  }

  return (
    <SettingsForm
      {...props}
      className={cn("max-w-3xl", props.className)}
      form={form}
      onFormSubmit={onSubmit}
      isPending={isPending}
      title="User Information"
      description="Update user information."
    >
      <div className="flex gap-4">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input {...field} disabled={!isCurrentUser} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} disabled={!isCurrentUser} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input {...field} className="w-96" disabled={!isCurrentUser} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {showRoleSelect && (
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                disabled={isPartner}
              >
                <FormControl>
                  <SelectTrigger className="w-96">
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {userRoles.map((role) => {
                    // do not show the partner role
                    if (role.value === UserRole.Partner) {
                      return null;
                    }
                    return (
                      <SelectItem key={role.value} value={role.value}>
                        {role.label}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </SettingsForm>
  );
};

export const UserInformationFormSkeleton = ({
  ...props
}: Omit<UserInformationFormProps, "user">) => {
  return (
    <Card {...props} className={cn("max-w-3xl animate-pulse", props.className)}>
      <CardHeader>
        <CardTitle>User Information</CardTitle>
        <CardDescription>Update your user information.</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 ">
        <div className="flex gap-4">
          <div className="">
            <FormItem>
              <Label className="text-muted-foreground">First Name</Label>

              <Input disabled />
            </FormItem>
          </div>
          <div className="">
            <FormItem>
              <Label className="text-muted-foreground">Last Name</Label>

              <Input disabled />
            </FormItem>
          </div>
        </div>
        <FormItem>
          <Label className="text-muted-foreground">Email</Label>
          <Input className="w-96" disabled />
        </FormItem>
      </CardContent>
      <Separator className="mb-7" />
      <CardFooter className="flex justify-end gap-3">
        <Button variant="ghost" disabled>
          Discard Changes
        </Button>
        <Button disabled className="w-32">
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
