import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { ProductSummaryData } from "./getAllProductsSold";
import ResponsivePieChart from "./ResponsivePieChart";
import { LabelNameEnum } from "./SummaryPageAnalytics";

type Props = {
  productSummaryData: ProductSummaryData;
};

export const SubmissionChartCard = ({ productSummaryData }: Props) => {
  const cpuData: { name: string; value: number }[] = [];
  const gpuData: { name: string; value: number }[] = [];

  productSummaryData.validCPUs.forEach((prediction) => {
    const cpuProductName = prediction.labels.find(
      (label) => label.name === LabelNameEnum.CPUName,
    )?.value;
    if (cpuProductName !== undefined && cpuProductName !== "NULL") {
      // search for the product name in the cpuData array
      const productIndex = cpuData.findIndex(
        (product) => product.name === cpuProductName,
      );
      if (productIndex === -1) {
        cpuData.push({ name: cpuProductName, value: 1 });
      } else {
        cpuData[productIndex].value += 1;
      }
    }
  });

  productSummaryData.validGPUs.forEach((prediction) => {
    const gpuProductName = prediction.labels.find(
      (label) => label.name === LabelNameEnum.GPUName,
    )?.value;
    if (gpuProductName !== undefined && gpuProductName !== "NULL") {
      // search for the product name in the gpuData array
      const productIndex = gpuData.findIndex(
        (product) => product.name === gpuProductName,
      );
      if (productIndex === -1) {
        gpuData.push({ name: gpuProductName, value: 1 });
      } else {
        gpuData[productIndex].value += 1;
      }
    }
  });

  return (
    <Card className="col-span-1 row-span-1 flex flex-col p-2">
      <Tabs
        defaultValue={gpuData.length > 0 ? "GPU" : "CPU"}
        className="flex w-full  flex-col"
      >
        <TabsList className="w-fit self-end ">
          <TabsTrigger value="GPU">GPU</TabsTrigger>
          <TabsTrigger value={"CPU"}>CPU</TabsTrigger>
        </TabsList>
        <TabsContent value={"GPU"} className="h-[340px]">
          {gpuData.length > 0 ? (
            <ResponsivePieChart data={gpuData} />
          ) : (
            <div className="mt-32 text-center">
              No Eligible GPUs in this Submission
            </div>
          )}
        </TabsContent>
        <TabsContent value={"CPU"} className="h-[340px]">
          {cpuData.length === 0 ? (
            <div className="mt-32 text-center">
              No Eligible CPUs in this Submission
            </div>
          ) : (
            <ResponsivePieChart data={cpuData} />
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
};
