import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@/components/ui/button";
import { handleLogout } from "@/lib/handleLogout";
import { cn } from "@/lib/cn";
import { Icons } from "@/components/Icons";

type Props = {
  children?: React.ReactNode;
  className?: string;
  variant?: "outline" | "ghost" | "default";
  size?: "icon" | "default";
};

export const LogoutButton = ({
  children,
  className,
  variant = "outline",
  size = "icon",
}: Props) => {
  const { logout } = useAuth0();

  return (
    <Button
      className={cn(className)}
      variant={variant}
      size={size}
      onClick={() => handleLogout(logout)}
    >
      <Icons.logout className="h-4 w-4" />
      {children}
    </Button>
  );
};
