import { NavLink, useParams } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/cn";
import { SubmissionRoutes } from "../vendorSubmitRoutes";
import { UserProfileBubble } from "./UserProfileBubble";

// TODO: Create more logic to disable options appropriately
export const VendorSubmissionFlowTabularHeader = ({
  className,
}: {
  className?: string;
}) => {
  const { vendorId: vId, submissionId: sId } = useParams();
  const vendorId = Number(vId);
  const submissionId = Number(sId);

  const navOptions = [
    {
      label: "Upload",
      href: `/vendor-submit/${vendorId}`,
      requires: [],
    },
    {
      label: "Normalize",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/${SubmissionRoutes.Normalize}`,
      requires: [vendorId, submissionId],
    },
    {
      label: "Review",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/${SubmissionRoutes.Review}`,
      requires: [vendorId, submissionId],
    },
    {
      label: "Submit",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/${SubmissionRoutes.Summary}`,
      requires: [vendorId, submissionId],
    },
  ];

  return (
    <header
      className={cn(
        "sticky top-0 z-10 flex h-16 items-center border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60",
        className,
      )}
    >
      <nav className="container flex items-center justify-between">
        <div className="flex flex-1 items-center gap-2">
          {navOptions.map((option, index) => {
            const isDisabled = option.requires.some((r) => !r);
            const isLastItem = index === navOptions.length - 1;

            return !isDisabled ? (
              <div key={option.label} className="flex items-center">
                <NavLink
                  to={option.href}
                  className={({ isActive, isPending, isTransitioning }) => {
                    return cn(
                      "relative flex h-16 items-center gap-2 px-4 text-sm font-medium",
                      "text-muted-foreground hover:text-primary",
                      "before:content-[attr(data-step)]",
                      "before:flex before:items-center before:justify-center",
                      "before:h-5 before:w-5 before:rounded-full",
                      "before:border before:border-muted-foreground/30",
                      "before:text-xs before:text-muted-foreground/50",
                      isActive && [
                        "text-primary",
                        "before:border-primary before:bg-primary before:text-primary-foreground",
                        "after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary",
                      ],
                      (isPending || isTransitioning) &&
                        "cursor-wait opacity-50",
                    );
                  }}
                  data-step={index + 1}
                  end
                >
                  {option.label}
                </NavLink>
                {!isLastItem && (
                  <div className="h-4 w-px bg-border" aria-hidden="true" />
                )}
              </div>
            ) : (
              <div key={option.label} className="flex items-center">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={cn(
                        "relative flex h-16 items-center gap-2 px-4 text-sm font-medium",
                        "text-muted-foreground/50",
                        "before:content-[attr(data-step)]",
                        "before:flex before:items-center before:justify-center",
                        "before:h-5 before:w-5 before:rounded-full",
                        "before:border before:border-muted-foreground/20",
                        "before:text-xs before:text-muted-foreground/30",
                        "cursor-not-allowed",
                      )}
                      data-step={index + 1}
                    >
                      {option.label}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" className="max-w-[200px]">
                    <p className="text-sm">
                      Please upload or select a submission to continue
                    </p>
                  </TooltipContent>
                </Tooltip>
                {!isLastItem && (
                  <div className="h-4 w-px bg-border" aria-hidden="true" />
                )}
              </div>
            );
          })}
        </div>

        <div className="flex items-center pl-4">
          <UserProfileBubble />
        </div>
      </nav>
    </header>
  );
};
