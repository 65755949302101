import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/Icons";
import { OrgUsersTableQuery } from "@/gql/graphql";
import { UserInformationForm } from "../../account/general/UserInformationForm";

interface EditUserDialogProps {
  user: NonNullable<OrgUsersTableQuery["users"][number]>;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({ user }) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem
          className="cursor-pointer gap-2"
          onSelect={(e) => {
            e.preventDefault();
          }}
        >
          <Icons.edit className="h-4 w-4" />
          Edit User
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Edit User Information</DialogTitle>
        </DialogHeader>
        <UserInformationForm
          user={user}
          className="border-none shadow-none"
          showRoleSelect={true}
          onSuccess={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};
