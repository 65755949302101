import { RouteObject } from "react-router-dom";

import { Icons } from "@/components/Icons";

// import { getQueryOptions as getTransformationTypeListQueryOptions } from "./organization/data-action/TransformationTypeList";
// import { ExportPage } from "./organization/export/ExportPage";
import { OrgGeneralPage } from "./organization/general/OrgGeneralPage";
import { MembersPage } from "./organization/member/MembersPage";
import { SubmissionTypeEditLayout } from "./organization/submission-type/[submissionTypeId]/submission-type-edit-layout";
import { OrgSubmissionTypePage } from "./organization/submission-type/SubmissionTypesPage";
import { VendorsSettingsPage } from "./organization/vendors/VendorsSettingsPage";
import { SubmissionTypeEditPage } from "./organization/submission-type/[submissionTypeId]/submission-type-edit-page";
import { Permission } from "@/lib/hooks/permissions";
import { PermissionGuard } from "@/app/PermissionGaurd";
import { UserInformationPage } from "./account/general/UserInformationPage";

export enum SettingsRouteId {
  Organization = "Organization",
  User = "User",
  OrgGeneral = "Org General",
  Vendors = "Vendors",
  SubmissionTypes = "Submission Types",
  SubmissionType = "Submission Type",
  Members = "Members",
  Export = "Export",
  UserInformation = "User Information",
}

export const settingsRoutes = [
  {
    id: SettingsRouteId.User,
    path: "user",
    handle: {
      Icon: Icons.user,
    },
    children: [
      {
        id: SettingsRouteId.UserInformation,
        handle: {
          name: "Information",
          showInNav: true,
        },
        path: "info",
        element: <UserInformationPage key="account-general" />,
      },
    ],
  },
  {
    id: SettingsRouteId.Organization,
    path: "org",
    handle: {
      Icon: Icons.building,
    },
    loader: async () => {
      return null;
    },
    children: [
      {
        id: SettingsRouteId.OrgGeneral,
        handle: {
          name: "General",
          showInNav: true,
        },

        path: "",

        element: (
          <PermissionGuard permissions={[Permission.READ_ORG_DETAILS]}>
            <OrgGeneralPage key="org-general" />
          </PermissionGuard>
        ),
      },
      {
        id: SettingsRouteId.Vendors,
        handle: {
          name: "Vendors",
          showInNav: (permissions: Permission[]) =>
            permissions.includes(Permission.READ_ORG_PARTNERS) ||
            permissions.includes(Permission.ADMIN_ALL),
        },
        path: "vendors",

        element: (
          <PermissionGuard permissions={[Permission.READ_ORG_PARTNERS]}>
            <VendorsSettingsPage key="vendors" />
          </PermissionGuard>
        ),
      },
      {
        id: SettingsRouteId.SubmissionTypes,
        handle: {
          name: "Submissions",
          showInNav: true,
        },
        path: "submission-type",

        element: (
          <PermissionGuard permissions={[Permission.READ_ORG_SUBMISSIONS]}>
            <OrgSubmissionTypePage key="submission-types" />
          </PermissionGuard>
        ),
      },
      {
        id: SettingsRouteId.SubmissionType,
        handle: {
          name: "Submission",
          showInNav: false,
        },
        path: "submission-type/:submissionTypeId",
        element: (
          <PermissionGuard
            permissions={[
              Permission.READ_ORG_SUBMISSIONS,
              Permission.WRITE_ORG_SUBMISSIONS,
            ]}
            requireAll={true}
          >
            <SubmissionTypeEditLayout key="submission-type" />
          </PermissionGuard>
        ),
        loader: SubmissionTypeEditLayout.loader,
        children: [
          {
            id: "Submission Type Page",
            path: "",
            element: <SubmissionTypeEditPage />,
          },
        ],
      },
      {
        id: SettingsRouteId.Members,
        handle: {
          name: "Members",
          showInNav: true,
        },
        path: "member",
        element: (
          <PermissionGuard
            permissions={[
              Permission.WRITE_ORG_DETAILS,
              Permission.READ_ORG_DETAILS,
            ]}
          >
            <MembersPage key="members" />
          </PermissionGuard>
        ),
      },
      // {
      //   id: SettingsRouteId.Export,
      //   handle: {
      //     name: "Export Data",
      //     showInNav: true,
      //   },
      //   path: "export",
      //   element: (
      //     <PermissionGuard
      //       permissions={[
      //         Permission.READ_ORG_SUBMISSIONS,
      //         Permission.READ_ORG_PARTNERS,
      //       ]}
      //       requireAll={true}
      //     >
      //       <ExportPage key="export" />
      //     </PermissionGuard>
      //   ),
      // },
      // {
      //   id: "Data Actions",
      //   handle: {
      //     name: "Data Actions",
      //     showInNav: true,
      //   },
      //   path: "data-action",
      //   element: <OrgDataActionPage />,
      // },
      // {
      //   id: "Transformation Types",
      //   handle: {
      //     name: "Transformation Types",
      //     showInNav: false,
      //   },
      //   path: "data-action/transformation-type",
      //   element: <TransformationTypesPage />,
      // },
      // {
      //   id: "Transformation Type",
      //   handle: {
      //     name: "Transformation Type",
      //     showInNav: false,
      //   },
      //   loader: loader,
      //   path: "data-action/transformation-type/:transformationTypeId",
      //   element: <TransformationTypeEditPage />,
      // },
    ],
  },
] satisfies RouteObject[];
