import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/cn";

interface OrgNameDisplayProps extends React.ComponentProps<typeof Card> {
  name: string;
}

export const OrgNameDisplay = ({ name, ...props }: OrgNameDisplayProps) => {
  return (
    <Card {...props} className={cn("max-w-3xl", props.className)}>
      <CardHeader>
        <CardTitle>Organization Information</CardTitle>
        <CardDescription>
          To change your organization name, please contact support.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="font-medium">{name}</div>
      </CardContent>
    </Card>
  );
};

export const OrgNameDisplaySkeleton = () => {
  return (
    <Card className="max-w-3xl">
      <CardHeader>
        <Skeleton className="h-4 w-24" />
      </CardHeader>
    </Card>
  );
};
