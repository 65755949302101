import { useCallback } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useLoaderData, useParams } from "react-router-dom";
import { toast } from "sonner";

import { useUpdateNormalizeMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useUpdateNormalizeMapping";
import { queryClient } from "@/queryClient";

import { getSubmissionRouteQueryOptions } from "../getSubmissionRouteQueryOptions";
import { getSubmissionNormalMapQueryOptions } from "./getSubmissionNormalMapQueryOptions";
import { NormalizeMapping } from "./NormalizeMapping";
import { useReprocessSubmission } from "./useReprocessSubmission";
import { useExcelWorkbookFromFileId } from "@/lib/hooks/useExcelWorkbookFromFileId";
import { Spinner } from "@/components/Spinner";
import ErrorPage from "@/app/ErrorPage";

export const NormalizeMappingPage = () => {
  const { vendorId: vId, submissionId: sId } = useParams();
  const vendorId = Number(vId);
  const submissionId = Number(sId);
  // @ts-expect-error
  const { fileId: fId } = useLoaderData();
  const fileId = Number(fId);

  const { isPending, worksheet } = useExcelWorkbookFromFileId(fileId);

  //   the submission type, output columns, and it's history of mappings
  const { data: submissionTypeData } = useSuspenseQuery({
    ...getSubmissionNormalMapQueryOptions({
      vendorId,
      submissionId,
    }),
    select: (data) => data?.submission?.type,
  });

  const mostRecentMapping = submissionTypeData?.normalizeMappings?.[0];

  const typedInitialMapping = (mostRecentMapping?.mapping ?? {}) as Record<
    string,
    string
  >;

  const initialMapping = Object.entries(typedInitialMapping).map(
    ([sourceHeader, targetHeader]) => ({
      sourceHeader,
      targetHeader,
    }),
  );

  const targetHeaders = (submissionTypeData?.outputColumns ?? []).map(
    (col) => col.name,
  );

  const queryOptions = getSubmissionRouteQueryOptions({
    submissionId,
    vendorId,
  });

  const { mutate: updateMapping, isPending: isUpdatePending } =
    useUpdateNormalizeMapping();
  const { reprocessSubmission, isPending: isReprocessSubmissionPending } =
    useReprocessSubmission({ submissionId });

  const onUpdateExistingMapping = useCallback(
    (mapping: Record<string, string>) => {
      updateMapping(
        {
          data: {
            mapping,
          },
          where: {
            id: mostRecentMapping?.id,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: queryOptions.queryKey,
            });
            toast.success("Mapping updated successfully", {
              duration: 800,
            });
            reprocessSubmission();
          },
          onError: (error) => {
            toast.error(`Failed to update mapping: ${error.message}`);
          },
        },
      );
    },
    [updateMapping, mostRecentMapping?.id],
  );

  if (isPending) {
    return (
      <div className="flex items-center justify-center">
        <Spinner size={"2xl"} />
      </div>
    );
  } else if (!worksheet || !targetHeaders) {
    return <ErrorPage />;
  } else {
    return (
      <NormalizeMapping
        worksheet={worksheet}
        initialMapping={initialMapping}
        targetHeaders={targetHeaders}
        onSaveMapping={onUpdateExistingMapping}
        isPending={isUpdatePending || isReprocessSubmissionPending}
        buttonText={"Update & Reprocess Submission"}
      />
    );
  }
};
