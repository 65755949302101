import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Spinner } from "@/components/Spinner";
import { useSetJwtCookieAndBearer } from "@/lib/hooks/Auth0";
import ErrorPage from "./ErrorPage";

export const AuthenticationGuard = ({
  component,
}: {
  component: React.ComponentType<any>;
}) => {
  const { hasError } = useSetJwtCookieAndBearer();

  if (hasError) {
    return <ErrorPage />;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    ),
  });

  return <Component />;
};
