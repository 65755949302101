import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TransformationStatus } from "@/gql/graphql";
import { CheckCircle, Sparkles, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";

import { getSubmissionTransformationsQueryOptions } from "../../../../../../components/shared-components/transformation-predictions-table/getSubmissionTransformationsQueryOptions";
import { useApproveTransformation } from "../../../../../../lib/hooks/mutations/useApproveTransformation";
import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { EnrichmentsList } from "./EnrichmentsList";
import { ListItem } from "./ListItem";
import {
  ReviewRoutes,
  SubmissionRoutes,
} from "@/app/vendor-submit/vendorSubmitRoutes";

export const YourExtractionsPage = () => {
  const location = useLocation();
  const { submissionId: pId } = useParams();
  const submissionId = Number(pId);
  const queryOptions = getSubmissionTransformationsQueryOptions(submissionId);
  const { data } = useQuery(queryOptions);
  const navigate = useNavigate();

  const transformations = (data?.submission?.transformations ?? []).sort(
    (a, b) => a.id - b.id,
  );
  const normalizedValidation = transformations.find(
    (transformation) => transformation.type.templateId === 1,
  );
  const enrichments = transformations.filter(
    (transformation) => transformation.type.templateId !== 1,
  );

  const approvedEnrichments = enrichments.filter(
    (transformation) => transformation.status === TransformationStatus.Approved,
  ).length;

  const { approveTransformation } = useApproveTransformation();

  const allApproved =
    approvedEnrichments === enrichments.length &&
    normalizedValidation?.status === TransformationStatus.Approved;

  return (
    <div className="flex h-screen flex-col">
      <ScrollArea className=" flex-1">
        <div className="min-h-full w-full">
          <BodyLayout className="">
            <Header
              title="Your Data Extractions"
              description="Click on the items below to review the extracted data. We've surfaced the most uncertain items for your review (corrections you make will apply to all future submissions)."
            />
            <section className="flex w-full flex-col space-y-8 pt-6 md:px-16">
              {/* Normalize & Validate Section */}
              <div className="rounded-lg border border-border/50 bg-card p-6">
                <div className="mb-2 flex items-center">
                  <CheckCircle className="mr-2 h-5 w-5 text-muted-foreground" />
                  <h2 className="text-lg font-semibold">
                    Normalize & Validate
                  </h2>
                </div>
                <ListItem
                  name="Normalized Data Validations"
                  description="The normalized output file has been validated against our internal data model."
                  href={`${location.pathname}/${ReviewRoutes.Validation}`}
                  approved={
                    normalizedValidation?.status ===
                    TransformationStatus.Approved
                  }
                  onApprove={() => {
                    if (
                      normalizedValidation?.id &&
                      normalizedValidation?.status
                    ) {
                      approveTransformation(
                        normalizedValidation?.id,
                        normalizedValidation?.status,
                      );
                    }
                  }}
                />
              </div>

              {/* Enrichments Section */}
              <div className="rounded-lg border border-border/50 bg-card p-6">
                <div className="mb-2 flex items-center">
                  <Sparkles className="mr-2 h-5 w-5 text-muted-foreground" />
                  <h2 className="text-lg font-semibold">Enrichments</h2>
                  <Badge variant="outline" className="ml-2 h-6">
                    {approvedEnrichments}/{enrichments.length}
                  </Badge>
                </div>
                <EnrichmentsList enrichments={enrichments} />
              </div>
            </section>
          </BodyLayout>
        </div>
      </ScrollArea>
      {/* Persistent Action Bar */}
      <div className="sticky bottom-0 border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container flex items-center justify-between py-4">
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            {allApproved ? (
              <span className="flex items-center gap-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                All items reviewed
              </span>
            ) : (
              <span>
                {approvedEnrichments +
                  (normalizedValidation?.status ===
                  TransformationStatus.Approved
                    ? 1
                    : 0)}
                /{enrichments.length + 1} items reviewed
              </span>
            )}
          </div>

          <div className="flex gap-4">
            <Button variant="outline" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              onClick={() => navigate(`../${SubmissionRoutes.Summary}`)}
              disabled={!allApproved}
              className="gap-2"
            >
              Continue to Summary
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
