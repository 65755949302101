import { SettingsRouteId } from "@/app/dashboard/settings/settingsRoutes";
import { UserRole } from "@/gql/graphql";
import { useDecodedJwtCookie } from "@/lib/hooks/Auth0";

export enum Permission {
  READ_PARTNER_SUBMISSIONS = "read:partner_submissions",
  WRITE_PARTNER_SUBMISSIONS = "write:partner_submissions",
  READ_ORG_SUBMISSIONS = "read:org_submissions",
  READ_ORG_PARTNERS = "read:org_partners",
  READ_ORG_DETAILS = "read:org_details",
  WRITE_ORG_SUBMISSIONS = "write:org_submissions",
  WRITE_ORG_PARTNERS = "write:org_partners",
  WRITE_ORG_DETAILS = "write:org_details",
  ADMIN_ALL = "admin:all",
}

export const usePermissions = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const permissions = (decodedJwt?.permissions as Permission[]) || [];

  const hasPermission = (permission: Permission): boolean => {
    return (
      permissions.includes(permission) ||
      permissions.includes(Permission.ADMIN_ALL)
    );
  };

  const hasAnyPermission = (requiredPermissions: Permission[]): boolean => {
    return requiredPermissions.some((permission) => hasPermission(permission));
  };

  const hasAllPermissions = (requiredPermissions: Permission[]): boolean => {
    return requiredPermissions.every((permission) => hasPermission(permission));
  };

  const getRole = (): UserRole | null => {
    const { decodedJwt } = useDecodedJwtCookie();
    const role = decodedJwt?.claims.role;

    return role as UserRole | null;
  };

  return {
    permissions,
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    getRole,
  };
};

export const useIsOrgAdmin = () => {
  const { getRole } = usePermissions();
  return getRole() === UserRole.OrgAdmin;
};

export const useCanManageSubmissions = () => {
  const { hasAnyPermission } = usePermissions();
  return hasAnyPermission([
    Permission.WRITE_ORG_SUBMISSIONS,
    Permission.WRITE_PARTNER_SUBMISSIONS,
  ]);
};

export const useCanViewSettingsSection = (
  routeId: SettingsRouteId,
): boolean => {
  const { hasPermission } = usePermissions();

  switch (routeId) {
    case SettingsRouteId.OrgGeneral:
      return hasPermission(Permission.READ_ORG_DETAILS);
    case SettingsRouteId.Vendors:
      return hasPermission(Permission.READ_ORG_PARTNERS);
    case SettingsRouteId.SubmissionTypes:
      return hasPermission(Permission.READ_ORG_SUBMISSIONS);
    case SettingsRouteId.Members:
      return hasPermission(Permission.WRITE_ORG_DETAILS);
    case SettingsRouteId.Export:
      return (
        hasPermission(Permission.READ_ORG_SUBMISSIONS) &&
        hasPermission(Permission.READ_ORG_PARTNERS)
      );
    case SettingsRouteId.UserInformation:
      return true;
    default:
      return false;
  }
};

export const getRoleFromPermissions = (
  permissions: Permission[],
): UserRole | null => {
  const hasPermission = (permission: Permission): boolean => {
    return (
      permissions.includes(permission) ||
      permissions.includes(Permission.ADMIN_ALL)
    );
  };

  if (hasPermission(Permission.ADMIN_ALL)) {
    return UserRole.Developer;
  }

  if (
    [
      Permission.READ_ORG_DETAILS,
      Permission.WRITE_ORG_DETAILS,
      Permission.READ_ORG_SUBMISSIONS,
      Permission.WRITE_ORG_SUBMISSIONS,
      Permission.READ_ORG_PARTNERS,
      Permission.WRITE_ORG_PARTNERS,
    ].every(hasPermission)
  ) {
    return UserRole.OrgAdmin;
  }

  if (
    [
      Permission.READ_ORG_DETAILS,
      Permission.READ_ORG_SUBMISSIONS,
      Permission.WRITE_ORG_SUBMISSIONS,
      Permission.READ_ORG_PARTNERS,
    ].every(hasPermission)
  ) {
    return UserRole.OrgEditor;
  }

  return null;
};
