import { graphql } from "@/gql";
import { useGraphqlMutation } from "../graphql";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

export const useUpdateUserInformation = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();

  return useGraphqlMutation({
    mutation: graphql(`
      mutation UserGeneralInfo(
        $data: UserUpdateInput!
        $where: UserWhereUniqueInput!
      ) {
        updateOneUser(data: $data, where: $where) {
          firstName
          lastName
          email
          role
          partners {
            id
          }
        }
      }
    `),
    onSuccess: () => {
      toast.success("Account updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["MembersTable"],
      });
      onSuccess?.();
    },
  });
};
