import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
// import {
//   Card,
//   CardHeader,
//   CardTitle,
//   CardDescription,
//   CardContent,
// } from "@/components/ui/card";
// import { cn } from "@/lib/cn";
// import { CreateOrUpdateVendorFormDialog } from "./CreateOrUpdateVendorFormDialog";
import { VendorsTableWrapper } from "@/components/shared-components/vendors-table/VendorsTableWrapper";
// import { BulkUploadVendorDialog } from "./BulkUploadVendorDialog";

export function VendorsSettingsPage() {
  return (
    <DashboardScreen className="flex flex-col gap-4" scrollable>
      {/* <Card className={cn("max-w-3xl")}>
        <CardHeader>
          <CardTitle>Create Vendors</CardTitle>
          <CardDescription>
            Create a new vendor for your organization.
          </CardDescription>
        </CardHeader>
        <CardContent className="flex gap-4 ">
          <CreateOrUpdateVendorFormDialog />
          <BulkUploadVendorDialog />
        </CardContent>
      </Card> */}
      <div className="max-w-3xl">
        <VendorsTableWrapper />
      </div>
    </DashboardScreen>
  );
}
