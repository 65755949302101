import { env } from "@/env";

interface InviteUserResponse {
  // Add the response type based on what your API returns
  invitation_url?: string;
  id?: string;
  // ... other fields
}

interface InviteUserError {
  message: string;
  status: number;
}

export async function inviteAuth0User(
  email: string,
  role: UserRole,
  accessToken: string,
  partnerAuth0Id?: string,
): Promise<{ data?: InviteUserResponse; error?: InviteUserError }> {
  const url = `${env.VITE_PUBLIC_API_URL}/auth0/invite`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        email,
        role,
        partnerAuth0Id,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      return {
        error: {
          message: errorText || "Failed to invite user",
          status: response.status,
        },
      };
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    return {
      error: {
        message:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        status: 500,
      },
    };
  }
}

import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { UserRole } from "@/gql/graphql";

interface InviteUserVariables {
  email: string;
  partnerAuth0Id?: string;
  role: UserRole;
}

export function useInviteUser() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({
      email,
      partnerAuth0Id,
      role,
    }: InviteUserVariables) => {
      const accessToken = await getAccessTokenSilently();
      const result = await inviteAuth0User(
        email,
        role,
        accessToken,
        partnerAuth0Id,
      );

      if (result.error) {
        throw new Error(result.error.message);
      }

      return result.data;
    },
  });
}
