import { Link } from "react-router-dom";
import { Check, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/cn";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type Props = {
  onApprove: () => void;
  approved: boolean;
  name: string;
  description: string;
  href: string;
  disabled?: boolean;
};

export const ListItem = ({
  onApprove,
  approved,
  name,
  description,
  href,
  disabled = false,
}: Props) => {
  return (
    <Link
      to={href}
      className={cn(
        "group relative flex w-full items-center gap-3 rounded-md border bg-card px-4 py-3 transition-colors hover:bg-accent/5",
        disabled && "pointer-events-none opacity-50",
      )}
    >
      <div onClick={(e) => e.preventDefault()}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              onClick={onApprove}
              disabled={disabled}
              className={cn(
                "h-6 w-6 shrink-0 rounded-full p-0 transition-colors",
                approved && "text-green-500 hover:text-green-600",
                !approved && "text-muted-foreground hover:text-foreground",
              )}
            >
              {approved ? (
                <Check className="h-4 w-4" />
              ) : (
                <div className="h-2 w-2 rounded-full border border-current" />
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {approved ? "Approved" : "Click to approve"}
          </TooltipContent>
        </Tooltip>
      </div>

      <div className="flex-1 space-y-1">
        <h3 className="font-medium leading-none">{name}</h3>
        <p className="text-sm text-muted-foreground">{description}</p>
      </div>

      <ExternalLink className="h-3 w-3 text-muted-foreground" />
    </Link>
  );
};
