import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetJwtCookieAndBearer } from "@/lib/hooks/Auth0";
import { Spinner } from "@/components/Spinner";
import { env } from "@/env";
import ErrorPage from "../ErrorPage";

export function LoginPage() {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
  const { jwtCookie, hasError } = useSetJwtCookieAndBearer();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const invitation = params.get("invitation");
      const organization = params.get("organization");

      loginWithRedirect({
        authorizationParams: {
          audience: env.VITE_PUBLIC_AUTH0_AUDIENCE,
          organization: organization ?? undefined,
          invitation: invitation ?? undefined,
          // screen_hint: invitation ? "signup" : undefined,
          scope: "openid profile email read:roles",
        },
      }).catch((err) => {
        console.error("Login redirect failed:", err);
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, error]);

  useEffect(() => {
    if (isAuthenticated && jwtCookie) {
      navigate("/");
    }
  }, [isAuthenticated, jwtCookie, navigate]);

  if (error || hasError) {
    return <ErrorPage />;
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner className="h-8 w-8" />
    </div>
  );
}
