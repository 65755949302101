import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Spinner } from "@/components/Spinner";
import ErrorPage from "@/app/ErrorPage";

// a higher order component for wrapping any component with an error boundary and suspense

interface WithSuspenseAndErrorBoundaryProps<
  T extends React.ComponentType<any>,
> {
  Component: T;
  errorFallback?: React.ReactElement | null;
  suspenseFallback?: React.ReactElement | null;
}

export function WithSuspenseAndErrorBoundary<
  T extends React.ComponentType<any>,
>({
  Component,
  errorFallback,
  suspenseFallback,
}: WithSuspenseAndErrorBoundaryProps<T>): React.FC<
  React.ComponentPropsWithRef<T>
> {
  return function (props) {
    const defaultErrorFallback = <ErrorPage />;

    // centered spinner on screen
    const defaultSuspenseFallback = <Spinner size="2xl" />;

    return (
      <ErrorBoundary fallback={errorFallback || defaultErrorFallback}>
        <Suspense fallback={suspenseFallback || defaultSuspenseFallback}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

// for testing error boundarieså
export const ErrorTriggerComponent = () => {
  throw new Error("This is a test error!");
  return <div>You won't see this</div>;
};
