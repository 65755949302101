import { useQuery } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { ScrollArea } from "@/components/ui/scroll-area";
import { SubmissionStatus } from "@/gql/graphql";
import { cn } from "@/lib/cn";
import { queryClient } from "@/queryClient";

import { SummaryPageAnalytics } from "./analytics/SummaryPageAnalytics";
import { getVendorQueryOptions } from "../../../getVendorQueryOptions";
import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { useUpdateSubmission } from "./useUpdateSubmission";
import { usePrepareFileDownloadMutation } from "@/lib/hooks/mutations/usePrepareFileDownloadMutation";
import { useGetSubmissionOutputFile } from "./useGetSubmissionOutputFile";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";
import { File } from "lucide-react";
export const SummaryPage = () => {
  // const { submissionId: sId } = useParams();
  const { vendorId: vId, submissionId: sId } = useParams();
  const submissionId = Number(sId);
  const vendorId = Number(vId);
  const { data: vendorData, isLoading: isVendorDataLoading } = useQuery(
    getVendorQueryOptions(vendorId),
  );
  const { mutate: updateSubmission } = useUpdateSubmission();
  const navigate = useNavigate();
  const { data } = useGetSubmissionOutputFile({ submissionId });
  const { mutate } = usePrepareFileDownloadMutation();

  const outputFile = data?.submission?.outputFile;

  const downloadOutputFile = () => {
    if (outputFile) {
      mutate(
        { fileId: outputFile.id },
        {
          onSuccess: (data) => {
            const signedUrl = data.prepareFileDownload.signedUrl;
            onDownloadFileToComputer(signedUrl, outputFile.name);
          },
          onError: (error) => {
            toast.error(`Error downloading file: ${error.message}`);
          },
        },
      );
    }
  };

  return (
    <ScrollArea>
      <BodyLayout className="max-w-6xl">
        <Header
          title="Review Your Submission Information"
          description={
            <span>
              {`We've summarized the information you've provided at a high level.
              Take a look and make sure everything looks good before submitting. `}
              {outputFile && (
                <>
                  {`Download your output file `}
                  <button
                    onClick={downloadOutputFile}
                    className="inline-flex items-center gap-1 text-primary hover:underline focus:outline-none"
                  >
                    {"here"}
                    <File className="h-3 w-3" />
                  </button>
                  .
                </>
              )}
            </span>
          }
        />

        <div className="flex gap-4 py-6">
          <HoverBorderGradient
            as="button"
            containerClassName=""
            onClick={() => {
              updateSubmission(
                {
                  data: {
                    status: { set: SubmissionStatus.ReadyForDelivery },
                  },
                  where: {
                    id: submissionId,
                  },
                },
                {
                  onSuccess: () => {
                    navigate(`/vendor-submit/${vendorId}`);
                    toast.success("Submission successfully submitted!");
                    queryClient.invalidateQueries({
                      queryKey: ["GetSubmissionsVendorUpload"],
                    });
                  },
                  onError: (error) => {
                    toast.error(`Error submitting: ${error.message}`);
                  },
                },
              );
            }}
            className={cn(
              "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
            )}
          >
            {isVendorDataLoading ? (
              <div className="flex items-center justify-center">
                <p className="mr-2">Submit to</p>
                <ThreeDots color="#a1a1aa" height={20} width={20} />
              </div>
            ) : (
              `Submit to ${vendorData?.vendor?.organization.name ?? "..."}`
            )}
          </HoverBorderGradient>
        </div>

        <SummaryPageAnalytics />
      </BodyLayout>
    </ScrollArea>
  );
};
