import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { GetVendorDataQuery } from "@/gql/graphql";
import { CreateOrUpdateVendorForm } from "./CreateOrUpdateVendorForm";

type Props = {
  vendorData?: GetVendorDataQuery["vendor"];
  isLoading?: boolean;
};

export const CreateOrUpdateVendorFormDialog = ({
  vendorData,
  isLoading = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger disabled>
        <Button variant={"outline"} disabled>
          {vendorData ? "Update" : "Create New"} Vendor
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {vendorData ? "Update" : "Create New"} Vendor
        </DialogHeader>
        {!isLoading && (
          <CreateOrUpdateVendorForm
            vendorData={vendorData}
            setIsOpen={setIsOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
