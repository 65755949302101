import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { Column } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";

interface SortButtonProps<T> {
  column: Column<T>;
  label: string;
}

export function SortButton<T>({ column, label }: SortButtonProps<T>) {
  const handleSort = () => {
    const currentSort = column.getIsSorted();
    if (currentSort === false) {
      column.toggleSorting(false); // set to ascending
    } else if (currentSort === "asc") {
      column.toggleSorting(true); // set to descending
    } else {
      column.clearSorting(); // clear sorting
    }
  };

  return (
    <Button variant="ghost" onClick={handleSort}>
      {label}
      {column.getIsSorted() === "asc" ? (
        <ArrowUp className="ml-2 h-4 w-4" />
      ) : column.getIsSorted() === "desc" ? (
        <ArrowDown className="ml-2 h-4 w-4" />
      ) : (
        <ArrowUpDown className="ml-2 h-4 w-4" />
      )}
    </Button>
  );
}
