import { useRouteError, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/Icons";
import { LogoutButton } from "./dashboard/LogoutButton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type RouterError = {
  request?: {
    query?: string;
    variables: any;
  };
  response?: {
    error: string;
    headers: any;
    status?: number;
  };
};

export default function ErrorPage() {
  const error = useRouteError() as RouterError;
  const navigate = useNavigate();
  const { logout, user, error: auth0Error } = useAuth0();

  let userMessage = "Something's not quite right on our end.";
  let statusCode: number | null = null;

  if (error) {
    statusCode = error.response?.status ?? 500;
    switch (error.response?.status) {
      case 401:
        userMessage = "Your session has expired. Redirecting you to login...";
        break;
      case 403:
        userMessage = "Looks like you don't have access to this area.";
        break;
      case 404:
        userMessage = "We couldn't find what you're looking for.";
        break;
      case 500:
        userMessage = "We're having some technical difficulties.";
        break;
      default:
        userMessage = "Something unexpected happened.";
    }
  }

  const handleReportBug = () => {
    const subject = encodeURIComponent("Bug Report: Error Page");
    const body = encodeURIComponent(
      `Error Details:\n` +
        `Status: ${statusCode}\n` +
        `Message: ${userMessage}\n` +
        `URL: ${window.location.href}\n` +
        `User: ${user?.email}\n` +
        `Time: ${new Date().toISOString()}\n` +
        `Auth0 Error: ${auth0Error?.message || "None"}\n` +
        `\nStack Trace:\n${
          error instanceof Error ? error?.stack : "No stack trace available"
        }`,
    );

    window.location.href = `mailto:help@datasteward.ai?subject=${subject}&body=${body}`;
  };

  useEffect(() => {
    if (
      error?.response?.status === 401 &&
      error?.response?.error.toLowerCase().includes("expired")
    ) {
      const timer = setTimeout(() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin + "/logout",
          },
        });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error, logout]);

  return (
    <div className="relative flex min-h-screen items-center justify-center bg-background">
      <div className="absolute bottom-6 left-6 z-50">
        <Tooltip>
          <TooltipTrigger>
            <LogoutButton />
          </TooltipTrigger>
          <TooltipContent side="right" sideOffset={6} align="center">
            <p>Logout</p>
          </TooltipContent>
        </Tooltip>
      </div>

      <div className="w-full max-w-md space-y-6 p-8 text-center">
        {/* <div className="mx-auto h-64 w-64">
          <img
            src="/broken-robot.svg"
            alt="Error Illustration"
            className="h-full w-full"
          />
        </div> */}

        {statusCode && (
          <div className="text-6xl font-bold text-muted-foreground">
            {statusCode}
          </div>
        )}

        <div className="space-y-4">
          <h1 className="text-2xl font-semibold tracking-tight">
            Oops! Something went wrong
          </h1>
          <p className="text-muted-foreground">{userMessage}</p>
        </div>

        <div className="flex flex-col justify-center gap-2 sm:flex-row sm:gap-4">
          <Button
            variant="outline"
            onClick={() => navigate(-1)}
            className="gap-2"
          >
            <Icons.arrowLeft className="h-4 w-4" />
            Go Back
          </Button>

          <Button onClick={handleReportBug} className="gap-2">
            <Icons.bug className="h-4 w-4" />
            Report Bug
          </Button>
        </div>

        {process.env.NODE_ENV === "development" && error instanceof Error && (
          <div className="mt-8 rounded-lg bg-muted p-4 text-left">
            <p className="break-all font-mono text-sm text-muted-foreground">
              {error?.stack}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
