import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { getTransformationPredictionsTableQueryOptions } from "@/components/shared-components/transformation-predictions-table/getTransformationPredictionsTableQueryOptions";
import { ValidationExcelTableSkeleton } from "@/components/shared-components/validation-excel-table/ValidationExcelTableSkeleton";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { queryClient } from "@/queryClient";

import { getSubmissionTransformationsQueryOptions } from "../../../../../../components/shared-components/transformation-predictions-table/getSubmissionTransformationsQueryOptions";
import { TransformationPredictionsTable } from "../../../../../../components/shared-components/transformation-predictions-table/TransformationPredictionsTable";
import { useApproveAll } from "../../../../../../components/shared-components/transformation-predictions-table/useApproveAll";
import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { SubmissionRoutes } from "@/app/vendor-submit/vendorSubmitRoutes";

export const EnrichmentPage = () => {
  const navigate = useNavigate();
  const { submissionId: pId, transformationId: tId } = useParams();
  const submissionId = Number(pId);
  const transformationId = Number(tId);
  const { data } = useQuery(
    getSubmissionTransformationsQueryOptions(submissionId),
  );
  const options = useMemo(
    () => getTransformationPredictionsTableQueryOptions(transformationId),
    [transformationId],
  );
  const { data: predictionRowData, isLoading } = useQuery(options);

  // sort by id ascending
  const transformations = (data?.submission?.transformations ?? []).sort(
    (a, b) => a.id - b.id,
  );
  const enrichments = transformations.filter(
    (transformation) => transformation.type.templateId !== 1,
  );
  // get the current index of the transformation
  const currentIndex = enrichments.findIndex((t) => t.id === transformationId);
  const currentTransformation = enrichments[currentIndex];
  const nextTransformation = enrichments[currentIndex + 1];

  const hasLowConfidencePredictions = predictionRowData?.predictions.some(
    (prediction) => {
      // a label is low confidence if there is no feedback and the confidence is less than 0.5
      return (
        prediction.labels.some(
          (label) => label.feedback.length === 0 && label.confidence < 0.5,
        ) ?? false
      );
    },
  );
  const { onApproveAll } = useApproveAll();
  const onNavigateNext = () => {
    if (nextTransformation == null) {
      navigate(`../../${SubmissionRoutes.Summary}`);
      return;
    }
    queryClient.invalidateQueries({ queryKey: options.queryKey });

    navigate(`../${nextTransformation.id}`);
  };

  return (
    <ScrollArea className="max-w-full">
      <BodyLayout className="">
        <Header
          title={`${currentTransformation?.name} ${currentIndex + 1}/${
            enrichments.length
          }`}
          description="Click on the items below to review the extracted data. We've surfaced the most uncertain items for your review (corrections you make will apply to all future submissions)"
        />
        <div className="flex justify-between gap-6 py-6">
          <Button
            variant={"outline"}
            onClick={() => {
              navigate("..");
            }}
          >
            <Icons.chevronsLeft className="mr-2 h-4 w-4" />
            {"Back to Overview"}
          </Button>

          {hasLowConfidencePredictions ? (
            <AlertDialog>
              <AlertDialogTrigger>
                <Button variant={"outline"}>
                  {nextTransformation != null
                    ? `${nextTransformation.name}`
                    : "Go To Submit"}
                  <Icons.chevronsRight className="ml-2 h-4 w-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>Caution</AlertDialogHeader>
                <AlertDialogDescription>
                  Some low confidence extractions have not been reviewed. You
                  may be missing out on important information.
                </AlertDialogDescription>
                <AlertDialogFooter>
                  <AlertDialogCancel>Go Back</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      onApproveAll();
                      onNavigateNext();
                    }}
                  >
                    {"Approve All & Continue"}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          ) : (
            <Button
              onClick={() => {
                onApproveAll();
                onNavigateNext();
              }}
            >
              {nextTransformation?.name ?? "Go To Submit"}
              <Icons.chevronsRight className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>

        <div className="w-full pt-6">
          {isLoading ? (
            <ValidationExcelTableSkeleton />
          ) : (
            <TransformationPredictionsTable />
          )}
        </div>
      </BodyLayout>
    </ScrollArea>
  );
};
