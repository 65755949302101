import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql/gql";
import { UserWhereUniqueInput } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

import { useDecodedJwtCookie } from "../Auth0";

const query = graphql(`
  query UserById($where: UserWhereUniqueInput!) {
    user(where: $where) {
      email
      createdAt
      firstName
      id
      lastName
      organization {
        id
        name
      }
      partners {
        id
        name
      }
      role
      updatedAt
    }
  }
`);

export const getUserQueryOptions = ({ id }: UserWhereUniqueInput) => {
  return createQueryOptions({
    query,
    variables: { where: { id } },
  });
};

export const useGetUserPartnerIdFromSession = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const partnerId = decodedJwt?.claims?.partnerId;
  return partnerId;
};

export const useCurrentUserQuery = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const id = decodedJwt?.claims?.id;
  const options = getUserQueryOptions({ id });

  return useSuspenseQuery(options);
};

export const useUserQuery = ({ id }: UserWhereUniqueInput) => {
  const options = getUserQueryOptions({ id });

  return useSuspenseQuery(options);
};
