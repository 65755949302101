import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { env } from "@/env";

interface DeleteUserError {
  message: string;
  status: number;
}

async function deleteAuth0User(
  userId: string,
  accessToken: string,
): Promise<{ success: boolean } | { error: DeleteUserError }> {
  try {
    const response = await fetch(
      `${env.VITE_PUBLIC_API_URL}/auth0/users/${userId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!response.ok) {
      const errorText = await response.text();
      return {
        error: {
          message: errorText || "Failed to delete user",
          status: response.status,
        },
      };
    }

    return { success: true };
  } catch (error) {
    return {
      error: {
        message:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        status: 500,
      },
    };
  }
}

export function useDeleteAuth0User() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId: string) => {
      const accessToken = await getAccessTokenSilently();
      const result = await deleteAuth0User(userId, accessToken);

      if ("error" in result) {
        throw new Error(result.error.message);
      }

      return result;
    },
    onSuccess: () => {
      // Invalidate and refetch users query after successful deletion
      queryClient.invalidateQueries({
        queryKey: ["MembersTable"],
      });
    },
  });
}
