import { cn } from "@/lib/cn";
import { UserProfileBubble } from "../../UserProfileBubble";

type Props = {
  title: string;
  description: string | JSX.Element;
  className?: string;
  showUserProfileBubble?: boolean;
};

export const Header = ({
  title,
  description,
  className,
  showUserProfileBubble = false,
}: Props) => {
  return (
    <header className={cn("relative w-full px-6", className)}>
      {showUserProfileBubble && (
        <div className="absolute right-6 top-6">
          <UserProfileBubble />
        </div>
      )}

      <div className="flex w-full flex-col items-center justify-center">
        <div className="max-w-2xl">
          <h1 className="text-center text-3xl font-semibold">{title}</h1>
          <h2 className="mt-2 text-center text-sm text-muted-foreground">
            {description}
          </h2>
        </div>
      </div>
    </header>
  );
};
