import { NavLink } from "react-router-dom";

import { cn } from "@/lib/cn";

import { SettingsRouteId, settingsRoutes } from "./settingsRoutes";

export function SettingsNav() {
  const orgRoutes = settingsRoutes.filter(
    (route) => route.id === SettingsRouteId.Organization,
  );

  const userRoutes = settingsRoutes.filter(
    (route) => route.id === SettingsRouteId.User,
  );

  const OrgIcon = orgRoutes[0].handle.Icon;
  const UserIcon = userRoutes[0].handle.Icon;

  return (
    <nav className="flex w-48 flex-col gap-6 px-6 py-6">
      <div className="flex flex-col text-sm ">
        <h2 className="mb-2 flex gap-2 uppercase text-muted-foreground">
          <UserIcon className="h-5 w-5" />
          User
        </h2>
        {userRoutes[0].children.map((route) => {
          const path = `user/${route.path}`;

          return (
            route.handle.showInNav && (
              <NavLink
                key={`settings-link-${route.id}`}
                to={path}
                end={route.path === ""}
                className={({ isActive }) =>
                  cn(
                    isActive
                      ? "text-foreground"
                      : "text-muted-foreground hover:text-foreground/90",
                    "ml-7 inline-flex h-10 flex-shrink-0 cursor-pointer items-center rounded-lg transition-all duration-200 ease-in-out",
                  )
                }
              >
                {route.handle.name}
              </NavLink>
            )
          );
        })}
      </div>
      <div className="flex flex-col text-sm ">
        <h2 className="mb-2 flex gap-2 uppercase text-muted-foreground">
          <OrgIcon className="h-5 w-5" />
          Organization
        </h2>
        {orgRoutes[0].children.map((route) => {
          const path = `org/${route.path}`; // Updated to use new path structure

          return (
            route.handle.showInNav && (
              <NavLink
                key={`settings-link-${route.id}`}
                to={path}
                end={route.path === ""}
                className={({ isActive }) =>
                  cn(
                    isActive
                      ? "text-foreground"
                      : "text-muted-foreground hover:text-foreground/90",
                    "ml-7 inline-flex h-10 flex-shrink-0 cursor-pointer items-center rounded-lg transition-all duration-200 ease-in-out",
                  )
                }
              >
                {route.handle.name}
              </NavLink>
            )
          );
        })}
      </div>
    </nav>
  );
}
