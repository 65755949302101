import { createBrowserRouter, redirect, RouteObject } from "react-router-dom";

import ErrorPage from "@/app/ErrorPage";
import App from "@/app/layout";
import { dashboardRoutes } from "./app/dashboard/dashboardRoutes";
import { Help } from "./app/help/page";
import { LoginPage } from "./app/login/LoginPage";
import { Logout } from "./app/logout/page";
import UnauthorizedPage from "@/app/UnauthorizedPage";
import { vendorSubmitRoutes } from "./app/vendor-submit/vendorSubmitRoutes";
import { VendorAuthError } from "./app/vendor-submit/VendorAuthError";
import { getDecodedJwtCookie } from "./lib/hooks/Auth0";
import { UserRole } from "./gql/graphql";

export const routes = [
  {
    path: "/",
    Component: App,
    ErrorBoundary: ErrorPage,
    children: [
      {
        id: "Old Splash Route",
        path: "",
        loader: () => {
          const decodedJwt = getDecodedJwtCookie();
          const role = decodedJwt?.claims.role as UserRole;
          if (role === UserRole.Partner) {
            return redirect("/vendor-submit");
          } else {
            return redirect("/dashboard");
          }
        },
      },
      dashboardRoutes,
      vendorSubmitRoutes,
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "unauthorized",
        element: <UnauthorizedPage />,
      },
      {
        path: "unauthorized-partner",
        element: <VendorAuthError />,
      },
    ],
  },
] satisfies RouteObject[];

export const router = createBrowserRouter(routes);
