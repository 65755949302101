import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/Icons";

export function VendorAuthError() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <div className="flex max-w-md flex-col items-center gap-2 text-center">
        <Icons.error className="h-12 w-12 text-muted-foreground" />
        <h1 className="text-2xl font-semibold">Partner Access Required</h1>
        <p className="text-muted-foreground">
          You cannot access the submission portal because your account is not
          associated with a partner organization. If you believe this is an
          error, please contact your organization administrator.
        </p>
      </div>
      <div className="flex gap-4">
        <Button variant="outline">
          <Link to="/dashboard">Return to Dashboard</Link>
        </Button>
        <Button>
          <Link to="/help">Need Help?</Link>
        </Button>
      </div>
    </div>
  );
}
