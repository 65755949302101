import { Permission, usePermissions } from "@/lib/hooks/permissions";

export const PermissionGuard: React.FC<{
  permissions: Permission | Permission[];
  requireAll?: boolean;
  children: React.ReactNode;
}> = ({ permissions, requireAll = false, children }) => {
  const { hasPermission, hasAllPermissions, hasAnyPermission } =
    usePermissions();

  const hasAccess = Array.isArray(permissions)
    ? requireAll
      ? hasAllPermissions(permissions)
      : hasAnyPermission(permissions)
    : hasPermission(permissions);

  if (!hasAccess) return null;

  return <>{children}</>;
};
